.aa-Autocomplete > .aa-Form,
.aa-DetachedFormContainer > .aa-Form {
  border-radius: 8px;
  border: 1px solid #cecbc2;
}

.aa-SubmitIcon {
  color: #7c7b78 !important;
}

.aa-Panel {
  z-index: 9999;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

[class^="text-"],
button,
input,
span {
  @apply antialiased;
}

@font-face {
  font-family: "GT Ultra Fine";
  src: local("GT Ultra Fine"), url(./fonts/GT-Ultra-Fine-Thin.woff) format("woff");
}

body {
  background-color: #fff;
}

a {
  text-decoration: none !important;
}

.gt-font {
  font-family: "GT Ultra Fine";
}

/* Custom classes for note contents */
.ba-note-content p {
  margin-bottom: 10px;
}
.ba-note-content a {
  color: #6eb8d4;
}
.dark .react-loading-skeleton {
  --base-color: #a7a7a7;
  --highlight-color: #949191;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block;
  background-color: var(--base-color);
  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.houzz-icon-link:hover {
  cursor: pointer;
}

.houzz-icon-link:hover svg {
  fill: #a95840;
}
